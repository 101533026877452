.project-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 120px;
}

.project-page h1 {
    font-size: 6vw;
    font-weight: bold;
    color: #00dfb3;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.project-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 2px solid #00dfb3;
    margin-bottom: 20px;
    cursor: pointer;
    max-width:1000px;
    width: 80vw;
}

.project-card h2 {
    color: #00dfb3;
    font-weight: bold;
}

.project-card h3 {
    color: #222;
    font-weight: bold;
    padding-bottom: 10px;
}

.project-card p {
    color: #fff;
}
  
.project-card img {
    width: auto; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    margin-right: 20px;
}
  
.project-info {
    flex: 1;
}

.read-more {
    display: flex;
    justify-content: right;
    padding-top: 20px;
}
  
.expanded-project-card {
    position: fixed;
    top: 55%;
    left: 50%;
    width: 80%;
    max-height: 90%;
    transform: translate(-50%, -50%);
    background-color: #222;
    padding: 20px;
    border: 1px solid #00dfb3;
    max-width: 80%;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.project-header {
    display: flex;
    direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin-bottom: 20px;
    width: 90%;
}

.expanded-project-card h2 {
    color: #00dfb3;
    font-weight: bold;
    font-size: 4vw;
}

.expanded-project-card h3 {
    color: #fff;
    font-weight: bold;
    font-size: 2vw;
    padding-bottom: 10px;
}

.expanded-project-card p {
    color: #fff;
    margin-bottom: 15px;
}
  
.expanded-project-card img {
    width: 35%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
  
.expanded-project-card .project-info {
    margin-bottom: 10px;
}
  
.expanded-project-card .project-info a {
    display: inline-block;
    text-decoration: none;
    margin-right: 10px;
}

.expanded-project-card .link {
    text-decoration: none;
    color: #fff;
    transition: transform 0.3s ease-in-out;
}

.expanded-project-card .link:hover {
    transform: translate(-2px, -5px);
    color: #00dfb3;
}
  
.close {
    padding: 10px 10px;
    font-size: 25px;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (max-width: 550px) {
    .project-card img {
        display: none;
    }

    .project-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .expanded-project-card h2 {
        font-size: 5vw;
    }

    .expanded-project-card h3 {
        font-size: 3.5vw;
    }
    
    .expanded-project-card img {
        width: 75%;
    }
}

@media screen and (min-width: 550px) {
    .read-more {
        display: none;
    }
}