.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-size: 1.2rem;
  background-color: #222;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 80px;
  border-radius: 13px;
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 99;
  transform: translate(-50%);
}

.logo {
    color: #00dfb3;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 700;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links {
    color: #00dfb3;
    text-decoration: none;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links:hover {
    background-color: #00dfb3;
    color: #222;
    border-radius: 8px;
    transition: all 0.15s ease-in-out;
}

.fa-bars, .fa-times {
    color: #00dfb3;
}

.menu-icon {
    display: none;
}

.logo {
    height: 35px;
}

.contact {
    color: #00dfb3;
    background-color: #222;
    border: 2px solid #00dfb3;
}

@media screen and (min-width: 850px) {
    .card-container {
        display: flex;
        flex-direction: row;
    }
    
    .contact {
        border-radius: 8px;
    }
}

@media screen and (max-width: 850px) {
    .NavbarItems {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #222;
        box-shadow: 0 5px 15px 0 rgba( 0, 0, 0, 0.25 );
        backdrop-filter: blur( 4px );
        border-radius: 13px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-links {
        display: block;
        width: 100%;
        padding: 2rem 0;
        color: #00dfb3;
    }

    .nav-links:hover {
        background-color: #00dfb3;
        color: #222;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
    }
}