/* PricingCard.css */

.pricing-card {
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border: 2px solid #00dfb3;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.pricing-card h2 {
    color: #00dfb3;
    font-size: 18px;
    margin-bottom: 10px;
}

.pricing-card .description {
    color: #fff;
    font-size: 16px;
    font-weight: 1000;
    margin-bottom: 15px;
}

.price {
    font-size: 50px;
    color: #00dfb3;
    margin-bottom: 15px;
}

.pricing-card ul {
    list-style-type: none;
    padding: 0;
}

.pricing-card ul li {
    color: #fff;
    margin-bottom: 10px;
}

.pricing-card ul li::before {
    content: "•";
    color: #00dfb3;
    display: inline-block;
    width: 1em;
}

.divider {
    border: 1px solid #00dfb3;
    margin: 20px 0;
}

.pricing-card:hover {
    background-color: #00dfb3;
    color: #fff;
    border: 2px solid #222;
}

.pricing-card:hover h2 {
    color: #222;
}

.pricing-card:hover .description {
    color: #fff;
}

.pricing-card:hover .price {
    color: #222;
}

.pricing-card:hover .divider {
    border: 1px solid #222;
}

.pricing-card:hover ul li::before {
    color: #222;
}
