* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
}

html {
  height: 80%;
  background: rgb(34, 34, 34);
  background: linear-gradient(315deg, rgba(34, 34, 34, 1) 2%, rgba(106, 106, 106, 1) 90%);
  background-attachment: fixed;
}

body {
  margin: 0;
  height: 80%;
}

.App {
  text-align: center;
  background-color: #333333;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
