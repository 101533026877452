.home {
    width: 100%;
    height: 80vh;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-text {
    margin-top: 100px;
    margin-bottom: 20px;
}

.text {
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-text h1 {
    font-size: 6vw;
    font-weight: bold;
    color: #00dfb3;
    border: 5px solid #00dfb3;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}

.main {
    /* transition: all 0.15s ease-in-out; */
    text-decoration: none;
}

.home-text h2 {
    font-size: 3vw;
    color: #222;
}

.home-text h3 {
    font-size: 2vw;
    color: #00dfb3;
}

.home-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Adjust this value as needed */
}

@media screen and (min-width: 1001px){
    .main:hover {
        background-color: #00dfb3;
        color: #222;
        box-shadow: 0 5px 15px 0 rgba( 0, 0, 0, 0.25 );
        /* transform: translate(-5px, -5px); */
    }
}

@media screen and (max-width: 1000px) {
    .home-text h1 {
        font-size: 8.5vw;
    }

    .home-text h2 {
        font-size: 4.5vw;
    }

    .home-text h3 {
        font-size: 3.5vw;
    }
}