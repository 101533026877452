.job-card {
    padding: 10px;
    border: 5px solid #00dfb3;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: all 0.15s ease-in-out;
    background-color: transparent; /* Set initial background color */
    box-shadow: none; /* Set initial box shadow */
    transform: none; /* Set initial transform */
}
  
.job-card.scrolled {
    background-color: #00dfb3;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    transform: translate(-1px, -5px);
}

.title {
    font-size: 5dvw;
    font-weight: bold;
    color: #222; /* Change the title color to white */
}

.company {
    font-size: 2dvw;
    font-weight: bold; /* Make the company name bold */
    color: #fff; /* Change the color of the company name to white */
}

.location {
    font-size: 1.5dvw;
    color: #fff; /* Change the color of the location to white */
    margin-bottom: 10px; /* Add margin to create space below the location */
    font-style: italic; /* Make the location text italic */
}

.descriptions {
    font-size: 1.5dvw;
    padding: 15px;
    color: #222;
    list-style-type: none; /* Remove the default bullet */
}

.descriptions li::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #00dfb3; /* Change the color to match your design */
    margin-right: 10px; /* Adjust the spacing as needed */
}

.job-card.scrolled .descriptions li::before {
    border-left-color: #222; /* Change the color of the bullet points to black on scroll */
}

@media only screen and (max-width: 850px) {
    .job-card {
        width: 90%;
    }

    .title {
        font-size: 6dvw;
    }
    
    .company {
        font-size: 5dvw;
    }
    
    .location {
        font-size: 4dvw;
    }
    
    .descriptions {
        font-size: 4dvw;
    }
}

@media only screen and (max-width: 550px) {
    .descriptions li::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 3.5px solid transparent;
        border-bottom: 3.5px solid transparent;
        border-left: 3.5px solid #00dfb3; /* Change the color to match your design */
        margin-right: 8px; /* Adjust the spacing as needed */
    }
}