.experience {
    width: 100%;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience h1 {
    font-size: 6dvw;
    font-weight: bold;
    color: #00dfb3;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.jobs {
    max-width: 94.5%;
}